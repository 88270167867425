import { CardHorizontal, CardVertical } from '/features/buildingBlocks/Card'
import { determineDocumentPathSyncWithParams } from '/machinery/determineDocumentPathSyncWithParams'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { useContrastingStyle } from '/machinery/StyleContext'
import { refToDataXLink } from '/machinery/tracking/pushToDataLayer'
import { routeMap } from '/routeMap'

import cssStyleContext from '/cssGlobal/style-context.css'
import styles from './CardList.css'

export function CardList({ cardList }) {
  const contrastingStyleContext = useContrastingStyle({ gray: cssStyleContext.contextBlack })

  const Card = cardList.slides?.length === 1 ? CardHorizontal : CardVertical
  const hasMultipleItems = cardList.slides?.length > 1

  return (
    <div className={styles.component}>
      <ContainerXl>
        <div className={styles.layout}>
          <div
            data-style-context={contrastingStyleContext}
            className={cx(styles.grid, hasMultipleItems && styles.hasMultipleItems)}
          >
            {cardList.slides?.map(x => (
              <Card
                key={x._key}
                title={x.title}
                type={x.subHeader}
                label={x.button?.label ? x.button.label : ''}
                href={x.button?.ref && determineDocumentPathSyncWithParams({
                  document: x.button.ref,
                  routeMap,
                  params: x.button.params
                })}
                dataX={refToDataXLink(x.button?.ref)}
                image={x.image}
              />
            ))}
          </div>
        </div>
      </ContainerXl>
    </div>
  )
}
